import styled from "styled-components";

export const Audio = styled.audio`
  height: 40px;
  width: 100%;
  visibility: hidden;
  
  &.has-recording {
    visibility: visible;
  }
`;