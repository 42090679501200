import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {DROPBOX_CLIENT_ID} from "../../config/environment";
import {Wrapper} from "./Wrapper";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {nonceStorageKey} from "../../config/constants";
import clsx from "clsx";

function generateUID() {
    // I generate the UID from two parts here
    // to ensure the random number provide enough bits.
    const firstPartNumber = (Math.random() * 46656) | 0;
    const secondPartNumber = (Math.random() * 46656) | 0;
    const firstPart = ("000" + firstPartNumber.toString(36)).slice(-3);
    const secondPart = ("000" + secondPartNumber.toString(36)).slice(-3);
    return firstPart + secondPart;
}

export const DropboxApplicationLink: React.FC = function () {
    const [search] = useSearchParams();
    const [nonce, setNonce] = useState<string | "pending">("pending");
    const profileLinkToken = search.get("profileLinkToken") ?? "";
    const [_, storeNonceStorageKey] = useLocalStorage(nonceStorageKey);

    const state = `${nonce}-${profileLinkToken}`

    useEffect(() => {
        const uid = generateUID();

        setNonce(uid);
        storeNonceStorageKey(uid);
    }, [setNonce, storeNonceStorageKey]);

    const loginUrl = `https://www.dropbox.com/oauth2/authorize?token_access_type=offline&client_id=${DROPBOX_CLIENT_ID}&redirect_uri=${encodeURI(window.location.protocol + "//" + window.location.host + "/link-response")}&response_type=code&state=${encodeURI(state)}`
    const missingNonce = nonce === "pending";

    return (
        <Wrapper>
            <a className={clsx({'not-ready': missingNonce})} href={loginUrl}>Proceed to Dropbox to Authenticate</a>
        </Wrapper>
    )
};