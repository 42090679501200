import { useDebugValue, useEffect, useState } from 'react';
import { stopMediaStream } from '../util/stopMediaStream';

export function useUserMedia(requested: boolean, constraints: MediaStreamConstraints) {
  const [stream, setStream] = useState<MediaStream | undefined>();
  const [error, setError] = useState();
  const [state, setState] = useState('pending');
  const [persistedConstraints] = useState<MediaStreamConstraints>(constraints)

  useDebugValue({ error, state, stream, requested });

  useEffect(() => {
    if (!requested) {
      return;
    }
    let canceled = false;

    setState('pending');
    navigator.mediaDevices.getUserMedia(persistedConstraints).then(
      newStream => {
        if (!canceled) {
          setState('resolved');
          setStream(newStream);
        }
      },
      error => {
        if (!canceled) {
          setState('rejected');
          setError(error);
        }
      }
    );

    return () => {
      canceled = true;
    };
  }, [persistedConstraints, requested]);

  useEffect(() => {
    if (!requested) {
      stream && stopMediaStream(stream);
      setStream(undefined);
    }
  }, [requested, stream])

  useEffect(() => () => stream && stopMediaStream(stream), [stream]);

  return { error, state, stream };
}