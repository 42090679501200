import styled from "styled-components";

export const Wrapper = styled.main`
  color: azure;
  font-size: 24px;
  font-weight: bold;
  
  .not-ready  {
    visibility: hidden;
  }
`;