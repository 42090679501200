import styled, {keyframes} from "styled-components";
import {noButtonStyle} from "../../styles/utility";
import React, {FC} from "react";
import clsx from "clsx";
import {MicrophoneLines} from "../icons/microphoneLines";
import {Xmark} from "../icons/Xmark";
import {Spinner} from "../icons/spinner";
import {Upload} from "../icons/Upload";
import {RecordingState} from "../../types/recordingState";

const glowGrow = keyframes`
  0% {
    padding: 4px;
  }
  
  50% {

    padding: 0;
  }
  
  100% {
    padding: 4px;
  }
`;

const RecordButtonWrapper = styled.button`
  ${noButtonStyle}
  display: flex;
  align-items: stretch;
  border-radius: 50%;
  background-color: #56565644;
  height: 48px;
  width: 48px;
  transition: 0.2s ease-out;
  animation-fill-mode: forwards;
  
  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover:not(.recording) {
    padding: 4px;
  }
  
  &.recording {
    animation: ${glowGrow} .8s ease-in-out infinite;
    .record-button-icon-wrapper {
      background-color: #FFD166;
    }
  }
  
  .record-button-icon-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 4px;
    background-color: #D9DBF1;
  }
`;

type ButtonProps = {
    recording?: boolean,
    onClick?: () => void,
    disabled?: boolean | undefined,
    children: React.ReactNode,
};

type RecordButtonProps = {
    recordingState: string,
    hasRecording: boolean,
    onStart: () => void,
    onStop: () => void,
};

export const Button: FC<ButtonProps> = ({ recording, onClick, disabled, children }) => (
    <RecordButtonWrapper className={clsx({recording: recording})} disabled={disabled} onClick={onClick}>
        <div className="record-button-icon-wrapper">
            {children}
        </div>
    </RecordButtonWrapper>
);

export const RecordButton: FC<RecordButtonProps> = ({ recordingState, hasRecording, onStop, onStart }) => {
    const recording = recordingState === RecordingState.Recording;

    const onClick = () => {
        if (recording) {
            onStop();
        } else {
            onStart();
        }
    }

    return (
        <Button recording={recording} onClick={onClick} disabled={hasRecording}>
            <MicrophoneLines height="30px" width="30px" className="record-button-icon" />
        </Button>
    )
};

export const ClearRecordingButton: FC<{recordingState: RecordingState, hasRecording: boolean, onClear: () => void}> = ({recordingState, hasRecording, onClear}) => {
    const isUploading = recordingState === RecordingState.Uploading;

    return (
        <Button disabled={!hasRecording || isUploading} onClick={onClear}>
            <Xmark height="30px" width="30px" className="clear-button-icon" />
        </Button>
    )
}

export const UploadButton: FC<{recordingState: RecordingState, hasRecording: boolean, uploadAudio: () => Promise<void>}> = ({recordingState, hasRecording, uploadAudio}) => {
    const isUploading = recordingState === RecordingState.Uploading;

    return (
        <Button disabled={!hasRecording || isUploading} onClick={uploadAudio}>
            {isUploading && <Spinner height="30px" width="30px" className="spin upload-button-icon" />}
            {!isUploading && <Upload height="30px" width="30px" className="upload-button-icon" />}
        </Button>
    )
}