import React, {useEffect, useMemo, useState} from "react";
import {RecordingSection} from "../../components/recordingSection";
import {RecordingButtons} from "../../components/RecordingButtons";
import {Audio} from "../../components/Audio";
import clsx from "clsx";
import {ClearRecordingButton, RecordButton, UploadButton} from "../../components/Button";
import {useUserMedia} from "../../hooks/useUserMedia";
import {API_URL} from "../../config/environment";
import {toast} from "react-toastify";
import {wait} from "../../util/Wait";
import {RecordingState} from "../../types/recordingState";
import toWav from "audiobuffer-to-wav";

const rawAudioMimeTypes = ['audio/webm;codecs=pcm', 'audio/ogg;codecs=opus', 'audio/webm;codecs=opus', 'audio/mp4'];
const uploadedAudioMimeType = 'audio/wav';

function useSupportedMimeType() {
    const rawAudioMimeType = useMemo<string>(() => {
        const bestAudioFormat: string | 'missing' =
            rawAudioMimeTypes.find(mimeType => MediaRecorder.isTypeSupported(mimeType)) ?? 'missing';

        if (bestAudioFormat === "missing") {
            throw new Error("no supported mime types");
        }

        return bestAudioFormat;
    }, []);

    return rawAudioMimeType;
}

const emptyBlob = new Blob();

const useDownmixedBlob = function (recording: Blob[], rawAudioMimeType: string, shouldDownMix: boolean){
    const [downmixedBlob, setDownmixedBlob] = useState<Blob>(emptyBlob);

    useEffect(() => {
        if (!shouldDownMix || recording.length === 0) {
            setDownmixedBlob(emptyBlob)
        }
        const audioContext = new AudioContext();
        const blob = new Blob(recording, { type: rawAudioMimeType });

        const runAsyncEffect = async () => {
            if(blob.size === 0) {
                setDownmixedBlob(emptyBlob);
                return;
            }

            const arrayBuffer = await blob.arrayBuffer()
            // Convert array buffer into audio buffer
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            const downmixContext = new OfflineAudioContext(
                1,
                audioBuffer.length,
                audioBuffer.sampleRate
            );

            const bufferSource = new AudioBufferSourceNode(downmixContext, {
                buffer: audioBuffer
            });
            bufferSource.start(0);
            bufferSource.connect(downmixContext.destination);

            const channelData = await downmixContext.startRendering();
            const wavBuffer = toWav(channelData, {float32: true});
            const wavBlob = new Blob([wavBuffer], { type: uploadedAudioMimeType });

            setDownmixedBlob(wavBlob);
        }

        runAsyncEffect().then();
    }, [recording, rawAudioMimeType, shouldDownMix]);

    return downmixedBlob;
}

export type RecorderProps = {
    showId: string;
    showTitle: string;
}

const recordConstraints = {
    audio: true
};

export const Recorder: React.FC<RecorderProps> = ({showId, showTitle}) => {
    const [recordingState, setRecordingState] = useState<RecordingState>(RecordingState.Pending);
    let shouldRecord = recordingState === RecordingState.Starting || recordingState === RecordingState.Recording;

    const { stream } = useUserMedia(shouldRecord, recordConstraints);

    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | undefined>(undefined);
    const [recording, setRecording] = useState<Blob[]>([]);
    const [src, setSrc] = useState<string>();
    const rawAudioMimeType = useSupportedMimeType();

    useEffect(() => {
        navigator.mediaDevices.getUserMedia(recordConstraints).then();
    })

    useEffect(() => {
        if (stream === undefined) {
            return;
        }

        const options = {
            audioBitsPerSecond: 256000,
            mimeType: rawAudioMimeType,
        };

        const recorder = new MediaRecorder(stream, options);
        setMediaRecorder(recorder);

        recorder.ondataavailable = function (e) {
            setRecording((r) => [...r, e.data]);
        };
    }, [stream, setMediaRecorder, setRecording, rawAudioMimeType]);

    useEffect(() => {
        if (mediaRecorder === undefined) {
            return;
        }


    }, [mediaRecorder, setRecording]);

    useEffect(() => {
        if (mediaRecorder === undefined) {
            return;
        }

        mediaRecorder.onstop = (_e) => {
            setRecordingState(RecordingState.Stopped);
            // setMediaRecorderHasStopped(true); todo: does it matter knowing when the recorder has stopped vs when we stopped
        }
    }, [mediaRecorder, recording, setRecording, setSrc]);

    const onStart = () => {
        setRecordingState(RecordingState.Starting);
    }

    useEffect(() => {
        if (mediaRecorder === undefined || !mediaRecorder.stream.active || recordingState !== RecordingState.Starting) {
            return;
        }

        setRecordingState(RecordingState.Recording);
        setSrc(undefined);
        mediaRecorder.start();
    }, [mediaRecorder, recordingState, setRecordingState, setSrc]);

    const downmixedBlob = useDownmixedBlob(recording, rawAudioMimeType, recordingState === RecordingState.Stopped);
    useEffect(() => {
        if (downmixedBlob.size > 0 && recordingState === RecordingState.Stopped && src === undefined) {
            setSrc(window.URL.createObjectURL(downmixedBlob));
        }
    }, [downmixedBlob, recordingState, src, setSrc]);

    const onStop = () => {
        mediaRecorder?.stop();
    }

    const onClear = () => {
        setRecording([]);
        setSrc(undefined);
        setMediaRecorder(undefined);
        setRecordingState(RecordingState.Pending);
    }

    const uploadAudio = async () => {
        setRecording([]);
        const data = new FormData()
        data.append('file', downmixedBlob)
        setRecordingState(RecordingState.Uploading);

        try {
            var result = await fetch(`${API_URL}/api/upload/${showId}`, {
                method: 'POST',
                body: data
            });

            await wait(500);

            if (result.status === 200) {
                toast("Message received!")
                onClear();
            } else {
                toast("Having issues uploading", {type: "error"});
                const message = await result.text();
                console.error(message);
            }
        }
        catch (e) {
            toast("Having issues uploading", {type: "error"});
            console.error(e);
        } finally {
            setRecordingState(RecordingState.Pending);
        }
    }

    const hasRecording = recordingState === RecordingState.Stopped;

    return (
        <RecordingSection>
            <h1 style={{textAlign: "center"}}>{showTitle}</h1>
            <RecordingButtons>
                <RecordButton recordingState={recordingState} hasRecording={hasRecording} onStart={onStart} onStop={onStop}/>
                <ClearRecordingButton recordingState={recordingState} hasRecording={hasRecording} onClear={onClear} />
                <UploadButton recordingState={recordingState} hasRecording={hasRecording} uploadAudio={uploadAudio} />
            </RecordingButtons>
            <Audio className={clsx({'has-recording': hasRecording})} controls src={src}></Audio>
        </RecordingSection>
    );
};