import styled from "styled-components";
import {Outlet} from "react-router-dom";
import {FC} from "react";

const RecordingSectionWrapperStyle = styled.div`
  background: rgb(242,88,46);
  background: radial-gradient(circle, rgba(242,88,46,1) 0%, rgba(255,209,102,1) 100%);
  min-height: 100vh;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const RecordingSectionWrapper: FC<{}> = () => (
    <RecordingSectionWrapperStyle>
        <Outlet />
    </RecordingSectionWrapperStyle>
)

export const RecordingSection = styled.div`
  max-width: 280px;
  width: calc(100% - 44px);
  padding: 22px;
  margin: 0 auto;
`;