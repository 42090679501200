import React from 'react';
import './App.css';
import {RecordingSectionWrapper} from "./components/recordingSection";
import {ToastContainer} from 'react-toastify';
import {Navigate, Route, Routes} from "react-router-dom";
import {DropboxApplicationLink, DropboxApplicationLinkResponse} from "./pages/DropboxApplicationLink";
import {Recorder, RecorderProps} from "./pages/Recorder";

const showList: (RecorderProps & { path: string })[] = [{
    showId: "ABC7341A-8EA2-43BC-85C5-FA791FF4B5E5",
    showTitle: "Warcraft Reloaded",
    path: "wcr",
}, {
    showId: "127CEAE0-948F-4EAD-AFD9-5B2D0028B82A",
    showTitle: "Recordley Test",
    path: "recordley-test",
}]

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<RecordingSectionWrapper />}>
          <Route index element={<Navigate replace to="/wcr" />} />
          <>
              {showList.map(({path, ...props}) => (
                  <Route key={path} path={path} element={<Recorder {...props} />} />
              ))}
          </>
          <Route path="link" element={<DropboxApplicationLink />} />
          <Route path="link-response" element={<DropboxApplicationLinkResponse />} />
        </Route>
      </Routes>
      <ToastContainer theme="colored" />
    </div>
  );
}

export default App;
