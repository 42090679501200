import {useSearchParams} from "react-router-dom";
import {FC, useEffect, useState} from "react";
import {API_URL} from "../../config/environment";
import {Wrapper} from "./Wrapper";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {nonceStorageKey} from "../../config/constants";

enum SaveStatus {
    Submitting,
    Success,
    Fail
}

const consumedNonce = "consumed";

export const DropboxApplicationLinkResponse: FC = function () {
    const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.Submitting)
    const [storedNonce, setStoredNonce] = useLocalStorage(nonceStorageKey);
    const [search] = useSearchParams();
    const state = search.get("state") ?? "";
    const code = search.get("code") ?? "";

    useEffect(() => {
        const [nonce, ...profileLinkTokenParts] = state.split('-')
        const profileLinkToken = profileLinkTokenParts.join('-');

        const nonceAlreadyConsumed = storedNonce === consumedNonce;
        const missingProfileLinkToken = !profileLinkToken;
        const nonceMismatch = nonce !== storedNonce;

        if (nonceAlreadyConsumed || missingProfileLinkToken || nonceMismatch) {
            return;
        }

        setStoredNonce(consumedNonce);

        const submitCodeForProfile = async function () {
            try {
                console.log("submitting")
                const result = await fetch(`${API_URL}/api/link-dropbox`, {
                    method: 'POST',
                    body: JSON.stringify({
                        profileLinkToken,
                        code
                    }),
                });
                if (result.status !== 200) {
                    setSaveStatus(SaveStatus.Fail);
                } else {
                    setSaveStatus(SaveStatus.Success);
                }
            } catch (e) {
                setSaveStatus(SaveStatus.Fail);
            }
        }

        submitCodeForProfile().then();
    }, [code, state, storedNonce, setSaveStatus, setStoredNonce]);

    return (
        <Wrapper>
            {saveStatus === SaveStatus.Submitting && <span>Linking dropbox, please wait!</span>}
            {saveStatus === SaveStatus.Success && <span>Dropbox linked!!!</span>}
            {saveStatus === SaveStatus.Fail && <span>Could not link dropbox</span>}
        </Wrapper>
    )
}